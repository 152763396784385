import React, { useState, useEffect } from "react";
import Web3 from "web3";
import "./Details.css";
import discord from "../../images/icon/discord-brands.svg";
import twitter from "../../images/icon/twitter-brands.svg";
import earth from "../../images/icon/plaanetIcon.png";

import eyeOpen from "../../images/eye_open.svg";
import eyeClose from "../../images/eye_close.svg";

import tokenABI from "../../tokenABI.json";
import rewardABI from "../../rewardABI.json";
import marcoABI from "../../marcoABI.json";
import kubABI from "../../kubABI.json";

import kublaiNFT from "../../images/kublai.png";
import marcoNFT from "../../images/marco.jpeg";

require("dotenv").config();
const {
  REACT_APP_TOKEN_ADDRESS,
  REACT_APP_REWARD_ADDRESS,
  REACT_APP_CONTRACT_MARCO,
  REACT_APP_CONTRACT_KUBLAI,
} = process.env;

const Connect = () => {
  const [tokenContract, setTokenContract] = useState();
  const [rewardContract, setRewardContract] = useState();
  const [marcoContract, setMarcoContract] = useState();
  const [kubContract, setKubContract] = useState();
  const [connectedAccount, setConnectedAccount] = useState("connect wallet");
  const [balance, setBalance] = useState(0);
  const [wallet, setWallet] = useState();
  const [kubNfts, setKubNfts] = useState(0);
  const [marcoNfts, setMarcoNfts] = useState(0);
  const [registered, setRegistered] = useState();
  const [marcoPicture, setMarcoPicture] = useState();
  const [kubPicture, setKubPicture] = useState();

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    loadWeb3();
  }, []);

  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      // await window.ethereum.enable();
      const web3 = window.web3;

      const chainIdHex = web3.currentProvider.chainId;
      if (chainIdHex != "0x89") {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
        });
      }

      const ct = new web3.eth.Contract(tokenABI, REACT_APP_TOKEN_ADDRESS);
      setTokenContract(ct);
      const ct1 = new web3.eth.Contract(rewardABI, REACT_APP_REWARD_ADDRESS);
      setRewardContract(ct1);
      const ct2 = new web3.eth.Contract(marcoABI, REACT_APP_CONTRACT_MARCO);
      setMarcoContract(ct2);
      const ct3 = new web3.eth.Contract(kubABI, REACT_APP_CONTRACT_KUBLAI);
      setKubContract(ct3);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async function connect() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      setWallet(metaMaskAccount[0]);
      let splitedMetaMaskAddress;
      if (metaMaskAccount) {
        splitedMetaMaskAddress =
          metaMaskAccount[0].substring(0, 6) +
          "......" +
          metaMaskAccount[0].substring(
            metaMaskAccount[0].length - 4,
            metaMaskAccount[0].length
          );
      }
      setConnectedAccount(splitedMetaMaskAddress);
      const chainIdHex = web3.currentProvider.chainId;
      console.log(chainIdHex);
      if (chainIdHex != "0x89") {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
        });
      }
      let balance = await tokenContract.methods
        .balanceOf(metaMaskAccount[0])
        .call();
      setBalance(balance);

      let marcos = await marcoContract.methods
        .balanceOf(metaMaskAccount[0])
        .call();
      setMarcoNfts(marcos);

      let kubs = await kubContract.methods.balanceOf(metaMaskAccount[0]).call();
      setKubNfts(kubs);

      let check = await rewardContract.methods
        .registered(metaMaskAccount[0])
        .call();
      setRegistered(check);

      // setConnected(true);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async function register() {
    setError("");
    setSuccess("");
    try {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      const chainIdHex = web3.currentProvider.chainId;
      if (chainIdHex != "0x89") {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
        });
      }
      await rewardContract.methods
        .Register()
        .send({ from: metaMaskAccount[0] });
      setSuccess("Registration Successful");
    } catch (error) {
      setError("Registration Failed");
    }
  }

  async function claim() {
    setError("");
    setSuccess("");
    try {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      const chainIdHex = web3.currentProvider.chainId;
      if (chainIdHex != "0x89") {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
        });
      }
      await rewardContract.methods.Claim().send({ from: metaMaskAccount[0] });

      let balance = await tokenContract.methods
        .balanceOf(metaMaskAccount[0])
        .call();

      setBalance(balance);
      setSuccess("Claim Succesfull");
    } catch (error) {
      setError("Claim Failed");
    }
  }

  function toggleWallet() {
    setHidden(!hidden);
  }

  return (
    <>
      <div className="header">
        <a
          href="https://www.silkroadgang.com/"
          className="menu"
          target="_blank"
        >
          Home
        </a>
        <a className="menu">Lazy Lounge Club</a>
        <a onClick={() => connect()} className="menu" target="_blank">
          {connectedAccount}
        </a>
      </div>
      <div className="container">
        <div className="dbox">
          <div className="DinnerBox">
            <h1 style={{ marginBottom: "3rem" }}>
              <span className="title">
                Wallet ID:
                <img
                  src={!hidden ? eyeOpen : eyeClose}
                  width="20"
                  style={{ marginBottom: "5px", color: "red" }}
                  onClick={() => toggleWallet()}
                />
                <span
                  style={{
                    fontSize: "30px",
                    marginLeft: "20px",
                  }}
                >
                  {!hidden ? wallet : "***********************************"}
                </span>
              </span>
            </h1>
            <h1>
              {" "}
              <span className="title">
                Total balance in wallet: {balance / Math.pow(10, 18)} $ilk
              </span>{" "}
            </h1>
            <h1>
              {" "}
              <span className="title">
                Total balance to claim: 10 $ilk a day
              </span>
            </h1>
            <h1>
              {" "}
              <span className="title">
                $ilk generate: {10 * marcoNfts} $ilk Generated
              </span>
            </h1>
            <div className="flexD">
              <h1>
                {" "}
                <span className="title"> Marco Polo: {marcoNfts}</span>
                <br />
                <img src={marcoNFT} width="100" />
              </h1>
              <h1>
                {" "}
                <span className="title"> Kublai Khan: {kubNfts}</span>
                <br />
                <img src={kublaiNFT} width="100" />
              </h1>
            </div>
          </div>
          <div className="buttons">
            <a href="http://mint.silkroadgang.com">
              <button>MINT Marco Polo</button>
            </a>
            {!registered ? (
              <button onClick={() => register()}>Register User</button>
            ) : (
              <button onClick={() => claim()}>CLAIM</button>
            )}
            <span className="error-message">{error}</span>
            <span className="success-message">{success}</span>
          </div>
        </div>
        <div>
          <ul class="iconsDesign">
            <li>
              <a href="https://discord.gg/7RkCDP6vHU" target="_blank">
                <img src={discord} alt="" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/silkroadgang" target="_blank">
                <img src={twitter} alt="" />
              </a>
            </li>
            <li>
              <a href="https://silkroadgang.com" target="_blank">
                <img src={earth} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Connect;
