import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Connect from "./components/Connect/Connect";
import Details from "./components/Details/Details";
function App() {
  return (
    <Router>
      {/* <div className="header">
        <a href="https://www.silkroadgang.com/" className='menu' target="_blank">Home</a>
        <a href="/details" className='menu'>Lazy Lounge Club</a>
        <a href="#" className='menu' target="_blank">Connect Wallet</a>
      </div> */}
      <Routes>
        <Route exact path="/" element={<Details />} />
        {/* <Route path="/details" element={<Details />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
